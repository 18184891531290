import { FormManager } from 'Shared/form_manager';
import { Utils } from 'Shared/utils';

// eslint-disable-next-line no-var
export var setupUsernameValidation = (window.setupUsernameValidation = function (
   usernameElement,
   validElement,
   invalidElement,
   usernamePattern,
   formManager,
   required
) {
   let originalUsername = usernameElement.value;
   required = typeof required !== 'undefined' ? required : true;

   let verifyThrottler = Utils.throttler(500, function () {
      formManager.validateField(usernameElement);
      // If the unique username field has an error and the user is still
      // focused on it, immediately present them with the error message on the
      // field.
      if (
         usernameElement.retrieve('FormManager:errorMessage') &&
         document.activeElement === usernameElement
      ) {
         formManager.showStatusMessage(usernameElement);
      }
   });

   usernameElement.addEvent('keyup', function (ev) {
      // If the user is tabbing through the field, have the form do the validation.
      // Otherwise, double validation occurs and the field gets recolored white
      // instead of red.
      if (ev.key !== 'tab') {
         verifyThrottler.fire();
      }
   });

   formManager.setValidator(usernameElement, function (el) {
      let uniqueNameInvalidMsg = _js('Username invalid.');
      let uniqueNameInvalidCharMsg = _js('Username must only contain a-z, 0-9, and underscores.');
      let uniqueNameInvalidBegMsg = _js('Username must begin with a letter.');
      let uniqueNameInvalidLengthMsg = _js('Username must be 3-15 characters long.');
      let uniqueNameExistsMsg = _js('Username exists.');
      let uniqueNameProfaneMsg = _js('Username cannot contain profanity.');
      let fieldRequiredMsg = _js('This field is required.');

      let username = el.value.toLowerCase().trim();
      // Usernames must contain 3-15 alphanumeric characters or
      // underscores only.

      if (username === '' || username === originalUsername) {
         validElement.hide();
         invalidElement.hide();
         if (required && !App.bypassUsernameRestrictions && username !== originalUsername) {
            return fieldRequiredMsg;
         }
         return;
      }
      // Check for valid characters and length.
      if (!new RegExp(usernamePattern).test(username)) {
         invalidElement.show();
         validElement.hide();

         if (username.length < 3 || username.length > 15) {
            return uniqueNameInvalidLengthMsg;
         }
         if (/^[^a-z]/.test(username)) {
            return uniqueNameInvalidBegMsg;
         }
         if (/[^a-z0-9]/.test(username)) {
            return uniqueNameInvalidCharMsg;
         }
         return uniqueNameInvalidMsg;
      }
      return new FormManager.AjaxIOValidator('validateUniqueUsername', function (isUnique) {
         if (!isUnique) {
            validElement.hide();
            invalidElement.show();
            // If the user is still focused on the element insert the error
            // manually so the user gets instant feedback.
            if (document.activeElement === usernameElement) {
               formManager.error(usernameElement, uniqueNameExistsMsg);
               formManager.showStatusMessage(usernameElement);
            }
            return uniqueNameExistsMsg;
         } else {
            validElement.show();
            invalidElement.hide();
         }
      }).validate(username);
   });
});
